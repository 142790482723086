import React, { useCallback } from 'react';
import styled from 'styled-components';
import { HELPCENTER_URL } from '../../../../consts';
import useI18n from '../../../../lib/use_i18n';
import { Link } from '../../styles/text';
import { modalZIndex } from '../../styles/variables';
import { Tabs } from '../../tabs';
import { SelectImage } from './select_image';
import { UploadImage } from './upload_image';

export const ImageUploadModal = ({
  Modal,
  allowSelect,
  close,
  fetchImages,
  formatHint,
  handleDelete,
  handleRename,
  handleSelect,
  handleUpload,
  images,
  name,
  selectedImageUrl,
  maxFileSize,
  maxWidth,
  minWidth,
  modalTitle,
  successMsg,
  uploadErrorMsg,
  validFileFormats,
  recommendedDimensions,
}) => {
  const { translate } = useI18n('image_uploader');
  const UploadImageWithProps = useCallback(
    () => (
      <>
        <UploadImage
          close={close}
          formatHint={formatHint}
          handleSelect={handleSelect}
          handleUpload={handleUpload}
          maxFileSize={maxFileSize}
          maxWidth={maxWidth}
          minWidth={minWidth}
          successMsg={successMsg}
          uploadErrorMsg={uploadErrorMsg}
          validFileFormats={validFileFormats}
        />
        <HelpLink />
      </>
    ),
    [
      close,
      formatHint,
      handleSelect,
      handleUpload,
      maxFileSize,
      maxWidth,
      minWidth,
      successMsg,
      uploadErrorMsg,
      validFileFormats,
    ]
  );
  return (
    <Modal
      style={{
        minHeight: '550px',
        display: 'flex',
        flexDirection: 'column',
        overflow: 'hidden',
      }}
      width="80vw"
      height="80vh"
      id={`image-uploader-modal_${name}`}
      title={modalTitle}
    >
      {allowSelect ? (
        <Tabs
          bodyStyle={{ overflow: 'hidden', height: '100%' }}
          tabs={[
            {
              name: 'select',
              label: translate('.select_tab'),
              component: ({ setActiveTab }) => (
                <>
                  <SelectImage
                    close={close}
                    fetchImages={fetchImages}
                    handleDelete={handleDelete}
                    handleRename={handleRename}
                    handleSelect={handleSelect}
                    images={images}
                    selectedImageUrl={selectedImageUrl}
                    setActiveTab={setActiveTab}
                    recommendedDimensions={recommendedDimensions}
                  />
                  <HelpLink offset={true} />
                </>
              ),
            },
            {
              name: 'upload',
              label: translate('.upload_tab'),
              component: UploadImageWithProps,
            },
          ]}
        />
      ) : (
        <UploadImageWithProps />
      )}
    </Modal>
  );
};

const StyledLink = styled(Link)`
  margin-top: -30px;
  z-index: ${modalZIndex + 10};
`;

const HelpLink = ({ offset }) => {
  const LinkeEl = offset ? StyledLink : Link;
  const { translate } = useI18n('image_uploader');

  return (
    <LinkeEl style={{ marginRight: 'auto' }} href={HELPCENTER_URL.OPTIMIZE_ADS}>
      {translate('.help_link')}
    </LinkeEl>
  );
};
