/**
 * Returns a function which inserts an item alphabetically into the list.
 */
export const createHandler = (items, setItems) => (added) => {
  // Clone the object to trigger a state update
  setItems(sortedInsert({ items, newItem: added }));
};

export const sortedInsert = ({ items, newItem, attr = 'name' }) => {
  let index = items.findIndex(
    (item) => newItem[attr].localeCompare(item[attr]) < 0
  );
  if (index === -1) index = items.length;
  items.splice(index, 0, newItem);
  return [...items];
};

export const sort = (a, b) => {
  if (typeof a !== typeof b) {
    console.warn('table sort type mismatch');
  }
  const itemA = isString(a) ? a.toLowerCase() : a;
  const itemB = isString(b) ? b.toLowerCase() : b;
  if (itemA < itemB) return -1;
  if (itemA > itemB) return 1;
  return 0;
};

const isString = (item) => typeof item === 'string';

export const basicSort = (field, ascending) => (a, b) => {
  const order = sort(a[field], b[field]);
  const multiplier = ascending ? 1 : -1;
  return order * multiplier;
};

// A wrapper function that returns a function which can be used as
// sort method callback.
// Call example:
//   ```
//   const sortedArray = someArray.sort(sortByMultipleCriteria(
//                         {by: "name", ascending: true},
//                         {by: "location": ascending: false}
//                       ))
//   ```
export const sortByMultipleCriteria = (...fieldAscendingArgs) => {
  return (a, b) => {
    let result = 0;

    fieldAscendingArgs.some(({ by, ascending }) => {
      result = basicSort(by, ascending)(a, b);
      return result !== 0;
    });

    return result;
  };
};

export const nestedSortWrapper = (primaryKey = 'name', ...keysArg) => {
  return (field, ascending) => {
    return (a, b) => {
      const keys = [primaryKey, ...keysArg];
      let order;
      keys.some((key) => {
        const sort = basicSort(key, ascending);
        order = sort(a[field], b[field]);
        return order;
      });
      return order;
    };
  };
};

export const dateSort = (field, ascending) => (a, b) => {
  const dateA = isString(a[field]) ? new Date(a[field]) : a[field];
  const dateB = isString(b[field]) ? new Date(b[field]) : b[field];
  const multiplier = ascending ? 1 : -1;
  return (dateA - dateB) * multiplier;
};

export const numberSort = (field, ascending) => (a, b) => {
  const valueA = a[field] ? Number(a[field]) : -Infinity;
  const valueB = b[field] ? Number(b[field]) : -Infinity;
  const order = sort(valueA, valueB);
  const multiplier = ascending ? 1 : -1;
  return order * multiplier;
};

export const haveSameElements = (arr1, arr2) => {
  const set1 = new Set(arr1);
  const set2 = new Set(arr2);

  return set1.size === set2.size && [...set1].every((item) => set2.has(item));
};

export const findMatchingElements = (arr1, arr2) => {
  const set2 = new Set(arr2);
  return arr1.filter((element) => set2.has(element));
};

export const updateElement = (arr, newEl, attr = 'id') => {
  return arr.map((el) => (el[attr] === newEl[attr] ? newEl : el));
};
